export const state = () => ({
  propertyTypes: [],
  propertyTypeCategories: [],
  propertyTypeCategoryGroups: [],
  rentPeriods: [],
  rentTypes: [],
  // cities:[],
  location: '',
  otherSpecification: '',
  loading: false,
  allowedRentTypes: [],
  allowedRentPeriods: [],
  otherReference: '',
  selectedReference: null,
});

export const getters = {
  selectedPropertyTypeCategoryGroup(state) {
    const poType = state.propertyTypeCategoryGroups.find(function (poType_) {
      return poType_.selected === true;
    });
    if (poType !== undefined) {
      return poType;
    }
    return null;
  },
  selectedPropertyTypeCategory(state) {
    const poType = state.propertyTypeCategories.find(function (poType_) {
      return poType_.selected === true;
    });
    if (poType !== undefined) {
      return poType;
    }
    return null;
  },
  filteredPropertyTypeCategories(state, getters) {
    if (getters.selectedPropertyTypeCategoryGroup != null) {
      return state.propertyTypeCategories.filter((category) => {
        return category.propertyTypeCategoryGroupId == getters.selectedPropertyTypeCategoryGroup.id;
      });
    }
    return [];
  },
  filteredRentTypes(state) {
    if (state.allowedRentTypes.length === 0) return [];
    const rentTypes = JSON.parse(JSON.stringify(state.rentTypes));
    return rentTypes.filter((rentType) => {
      return state.allowedRentTypes.includes(rentType.id);
    });
  },
  filteredRentPeriods(state) {
    if (state.allowedRentPeriods.length === 0) return [];
    const rentPeriods = JSON.parse(JSON.stringify(state.rentPeriods));
    return rentPeriods.filter((rentPeriod) => {
      return state.allowedRentPeriods.includes(rentPeriod.id);
    });
  },
  // eslint-disable-next-line no-unused-vars
  formattedPropertyTypes(state, getters) {
    let allData = {};
    state.propertyTypes.forEach(function (element) {
      if (!allData[element.propertyTypeCategoryId]) {
        allData[element.propertyTypeCategoryId] = [];
        allData[element.propertyTypeCategoryId].push(element);
      } else {
        allData[element.propertyTypeCategoryId].push(element);
      }
    });
    return allData;
  },
  selectedPropertyType(state) {
    const poType = state.propertyTypes.find(function (poType_) {
      return poType_.selected === true;
    });
    if (poType !== undefined) {
      return poType;
    }
    return null;
  },
  selectedRentType(state) {
    let selectedRentType = state.rentTypes.find(function (rentType) {
      return rentType.selected;
    });
    if (selectedRentType) {
      return selectedRentType;
    }
    return null;
  },
  selectedRentPeriod(state) {
    let selectedRentType = state.rentPeriods.find(function (rentType) {
      return rentType.selected;
    });
    if (selectedRentType) {
      return selectedRentType;
    }
    return null;
  },
  // eslint-disable-next-line no-unused-vars
  referenceList(state) {
    return [
      {
        id: 1,
        name: 'Dari Google',
        checked: false,
      },
      {
        id: 2,
        name: 'Referensi Teman',
        checked: false,
      },
      {
        id: 3,
        name: 'Event Properti',
        checked: false,
      },
      {
        id: 4,
        name: 'Iklan',
        checked: false,
      },
      {
        id: 5,
        name: 'Surat Kabar',
        checked: false,
      },
      {
        id: 6,
        name: null,
        checked: false,
      },
    ];
  },
  reference(state) {
    let text = '';
    if (state.selectedReference) {
      if (state.selectedReference.name) text = state.selectedReference.name;
      else text = state.otherReference;
    }
    return text;
  },
};

export const mutations = {
  RESTORE_INITIAL_STATE(state) {
    state.propertyTypes = [];
    state.propertyTypeCategories = [];
    state.rentPeriods = [];
    state.rentTypes = [];
    // state.cities = [];
    state.location = '';
    state.otherSpecification = '';
    state.loading = false;
    state.allowedRentTypes = [];
    state.allowedRentPeriods = [];
    state.otherReference = '';
    state.selectedReference = null;
  },
  SET_PROPERTY_TYPE_CATEGORY_GROUPS(state, payload) {
    payload.forEach(function (element, index_, arr_) {
      if (index_ === 0) {
        arr_[index_].selected = true;
      } else {
        arr_[index_].selected = false;
      }
    });
    state.propertyTypeCategoryGroups = payload;
  },
  SET_PROPERTY_TYPE_CATEGORIES(state, payload) {
    payload.forEach(function (element, index_, arr_) {
      if (index_ === 0) {
        arr_[index_].selected = true;
      } else {
        arr_[index_].selected = false;
      }
    });
    state.propertyTypeCategories = payload;
  },
  SET_SELECTED_PROPERTY_TYPE_CATEGORY_GROUPS(state, id) {
    state.propertyTypeCategoryGroups.forEach(function (property, index_, arr_) {
      if (property.id == id) {
        arr_[index_].selected = true;
      } else {
        arr_[index_].selected = false;
      }
    });
  },
  SET_SELECTED_PROPERTY_TYPE_CATEGORIES(state, id) {
    state.propertyTypeCategories.forEach(function (property, index_, arr_) {
      if (property.id == id) {
        arr_[index_].selected = true;
      } else {
        arr_[index_].selected = false;
      }
    });
  },
  SET_ALLOWED_RENT_TYPES(state, payload) {
    state.allowedRentTypes = payload;
  },
  SET_ALLOWED_RENT_PERIODS(state, payload) {
    state.allowedRentPeriods = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_PROPERTY_TYPES(state, payload) {
    payload.forEach(function (propertyType, index, arr) {
      arr[index].selected = false;
    });
    state.propertyTypes = payload;
  },
  SET_RENT_PERIODS(state, payload) {
    payload.forEach(function (propertyType, index, arr) {
      arr[index].selected = false;
    });
    state.rentPeriods = payload;
  },
  SET_RENT_TYPES(state, payload) {
    payload.forEach(function (propertyType, index, arr) {
      arr[index].selected = false;
    });
    state.rentTypes = payload;
  },
  // SET_CITIES(state, payload) {
  //     state.cities = payload;
  // },
  SET_LOCATION(state, payload) {
    state.location = payload;
  },
  SET_OTHER_SPEC(state, payload) {
    state.otherSpecification = payload;
  },
  SET_SELECTED_RENT_TYPE(state, rentTypeId) {
    state.rentTypes.forEach(function (rentType, index_, arr_) {
      if (rentType.id == rentTypeId) {
        arr_[index_].selected = true;
      } else {
        arr_[index_].selected = false;
      }
    });
  },
  SET_SELECTED_RENT_PERIOD(state, rentPeriodId) {
    state.rentPeriods.forEach(function (rentPeriod, index_, arr_) {
      if (rentPeriod.id == rentPeriodId) {
        arr_[index_].selected = true;
      } else {
        arr_[index_].selected = false;
      }
    });
  },
  SET_ACTIVE_PROPERTY_TYPE(state, propertyTypeId) {
    state.propertyTypes.forEach(function (propertyType, index, arr) {
      if (propertyType.id === propertyTypeId) {
        arr[index].selected = true;
      } else {
        arr[index].selected = false;
      }
    });
  },
  SET_OTHER_REF(state, payload) {
    state.otherReference = payload;
  },
  SET_SELECTED_REF(state, payload) {
    state.selectedReference = payload;
  },
};

export const actions = {
  async changePropertyType({ commit, dispatch }, propertyTypeId) {
    commit('SET_LOADING', true);
    commit('SET_ACTIVE_PROPERTY_TYPE', propertyTypeId);
    commit('SET_SELECTED_RENT_TYPE', null);
    commit('SET_SELECTED_RENT_PERIOD', null);
    await dispatch('getPropTypeRules', propertyTypeId);
  },

  async getData({ commit }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await this.$http.get('/api/v2/space_manager/get_data');
      commit('SET_PROPERTY_TYPES', data.data.propertyTypes);
      commit('SET_PROPERTY_TYPE_CATEGORY_GROUPS', data.data.propertyTypeCategoryGroups);
      commit('SET_PROPERTY_TYPE_CATEGORIES', data.data.propertyTypeCategories);
      commit('SET_RENT_PERIODS', data.data.rentPeriods);
      commit('SET_RENT_TYPES', data.data.rentTypes);
      // commit('SET_CITIES',data.cities)
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getPropTypeRules({ commit }, propertyTypeId) {
    try {
      commit('SET_LOADING', true);
      const { data } = await this.$http.get('/api/v2/space_manager/get_property_type_rules', {
        params: { propertyTypeId },
      });
      commit('SET_ALLOWED_RENT_TYPES', data.data.allowedRentTypes);
      commit('SET_ALLOWED_RENT_PERIODS', data.data.allowedRentPeriods);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async postRequest({ state, commit, getters, rootState, dispatch }) {
    try {
      commit('SET_LOADING', true);
      let postData = {
        propertyTypeId: getters.selectedPropertyType.id,
        rentPeriodId: getters.selectedRentPeriod ? getters.selectedRentPeriod.id : null,
        rentTypeId: getters.selectedRentType ? getters.selectedRentType.id : null,
        location: state.location,
        otherSpecification: state.otherSpecification,
        reference: getters.reference,
      };
      if (!rootState.global.loggedIn) {
        postData.registerData = {
          name: rootState.signUp.name,
          email: rootState.signUp.email,
          password: rootState.signUp.password,
          phone: rootState.signUp.phone,
        };
      }
      const { data } = await this.$http.post('/api/v2/space_manager/post_request', postData);
      if (data) {
        commit('RESTORE_INITIAL_STATE');
        dispatch('getData');
        return true;
      }
      return true;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
